
export default {
    props: {
        item: {
            type: Array,
            required: true
        },
        isListing: {
            type: Boolean,
            required: true
        },
        viewMode: {
            type: Boolean,
            required: false
        }
    },
    data () {
        return {
            timeSlots: this.generateTimeSlots(),
            start: '',
            end: '',
            selectedTimeSlots: this.initSelectedTimeSlots(),
            error: ''
        }
    },
    computed: {
        endOptions () {
            if (!this.start) { return [] }
            return this.timeSlots.filter((time) => {
                // filter out any timeslots that are already selected
                return !this.selectedTimeSlots.some((slot) => {
                    return time >= slot.start && time < slot.end
                })
            }).filter(time => time > this.start)
        }
    },
    watch: {
        item (newVal, oldVal) {
            if (newVal !== oldVal && this.isListing) {
                if (newVal[0]) {
                    this.selectedTimeSlots = newVal[0].timings
                }
            }
        }
    },
    methods: {
        generateTimeSlots () {
            const timeSlots = []
            for (let i = 0; i < 24; i++) {
                const hour = i < 10 ? `0${i}` : `${i}`
                timeSlots.push(`${hour}:00`)
                timeSlots.push(`${hour}:30`)
            }
            return timeSlots.filter((time) => {
                const [hours, minutes] = time.split(':')
                return Number(minutes) === 0 || Number(minutes) === 30
            })
        },
        initSelectedTimeSlots () {
            let array = [{
                start: '08:00',
                end: '20:00'
            }]
            if (this.isListing && this.item[0] && this.item[0].timings.length > 0) {
                array = this.item[0].timings
            }
            this.$emit('update', this.item, array)
            return array
        },
        updateendOptions () {
            this.end = ''
        },
        addTimeSlot () {
            this.error = ''
            if (this.start && this.end) {
                const newTimeSlot = { start: this.start, end: this.end }
                // check if the new time slot overlaps with any existing time slots
                const overlaps = this.selectedTimeSlots.some((slot) => {
                    return (this.start >= slot.start && this.start < slot.end) || (this.end > slot.start && this.end <= slot.end)
                })
                if (!overlaps) {
                    const startDate = new Date(`1970-01-01T${this.start}`)
                    const endDate = new Date(`1970-01-01T${this.end}`)

                    // check if the selected time range is valid (e.g. from time is before to time)
                    if (startDate >= endDate) {
                        this.error = 'Invalid time range selected'
                        return
                    }

                    // check if the selected time range has a 30-minute interval
                    const timeDiff = Math.abs(endDate.getTime() - startDate.getTime())
                    const minutesDiff = Math.round(timeDiff / (1000 * 30))
                    if (minutesDiff % 30 !== 0) {
                        this.error = 'Time range should have a 30-minute interval'
                        return
                    }

                    this.timeSlots.push({
                        start: this.start,
                        end: this.end
                    })
                    this.selectedTimeSlots.push({
                        start: this.start,
                        end: this.end
                    })
                    this.$emit('update', this.item, this.selectedTimeSlots)
                    this.start = ''
                    this.end = ''
                } else {
                    this.error = 'You already selected these dates'
                }
            }
        },
        removeTimeSlot (index) {
            if (this.isListing) {
                const obj = { ...this.item }
                obj[0].timings.splice(index, 1)
                this.$emit('update', this.item, obj)
            } else {
                this.selectedTimeSlots.splice(index, 1)
                this.$emit('update', this.item, [])
            }
        }
    }
}
